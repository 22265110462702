<template>
    <q-dialog
        :id="id"
        ref="modal"
        class="c-q-modal-fk"
        :content-css="{
            minHeight: 'calc(100vh - 60px)'
        }"
        :persistent="blockDismiss"
        :maximized="mxScreen_IsLowResolution || maximized"
    >
        <q-layout
            :class="`mdLayout no-border bg-${$q.dark.isActive ? 'dark' : 'white'}`"
            :content-style="{ 'minWidth': width }"
            header-class="no-border no-shadow"
            :header-style="{
                'min-height': mxScreen_IsLowResolution ? '80px' : '60px',
                'height': mxScreen_IsLowResolution ? '80px' : '60px',
                'margin-bottom': '10px'
            }"
            container
        >
            <q-header>
                <q-toolbar>
                    <!-- <div class="row justify-end col-sm-3"> -->
                    <div v-if="!disableDismiss">
                        <q-btn
                            id="btnLBCloseInner"
                            ref="ttCancelar"
                            icon="navigate_before"
                            flat
                            round
                            v-close-popup
                            @click="$emit('fechar')"
                        />
                    </div>
                    <!-- </div> -->

                    <q-toolbar-title>
                        <div class="q-title">
                            {{ title }}
                        </div>
                        <div
                            class="q-subtitle"
                            v-html="subtitle"
                        />
                    </q-toolbar-title>
                </q-toolbar>
            </q-header>
            <q-page-container>
                <slot />
            </q-page-container>
        </q-layout>
    </q-dialog>
</template>

<script>
import { mixinScreen } from 'lins-mixins'

export default {
    name: 'QModalView',

    mixins: [mixinScreen],

    props: {
        id: {
            type: String,
            default: 'modalView'
        },

        title: {
            type: String,
            required: true
        },

        subtitle: {
            type: String,
            default: ''
        },

        width: {
            type: String,
            default: ''
        },

        blockDismiss: {
            type: Boolean,
            default: false
        },

        disableDismiss: {
            type: Boolean,
            default: false
        },

        maximized: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        show() {
            this.$refs.modal.show()
        },

        hide() {
            this.$refs.modal.hide()
        }
    }
}
</script>

<style lang="scss" scoped>

.c-q-modal-fk{
    .c-layout-fk {
        padding: 10px 30px 10px 30px;
    }
}
</style>
